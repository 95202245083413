<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					이벤트 &middot; 기획전 
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub event">
			<!-- content -->
			<section class="content">
				<div class="box">
                    <div class="event__wrap">
                        <h3 class="list__title info-title" style="display:inline-block">
                            대한민국 숙박대전 사전인증 이벤트
                        </h3>
                        <div class="list__date grey3">
                            2021.11.04 ~ 2021.11.09
                        </div>
                    </div>
				</div>
                <div class="event__content">
                    <img src="@/assets/images/temp-info-event01.jpg" alt="">
                </div>
				<div class="event__rooms">
					<h3 class="event__roomstitle">
						관련 숙소
					</h3>
					<ul class="list list--full">
						<li>
							<div class="item">
								<router-link to="/rooms/1" class="list__link">
									<!-- [D] 뱃지 있을 시 추가 -->
									<div class="card__badge badge badge--coupon">
										<span class="badge__text">
											1만원 할인쿠폰
										</span>
										<span class="badge__text purple">
											선착순 할인쿠폰
										</span>
									</div>
									<div class="list__wrap">
										<div class="list__image">
											<div class="list__imagebox noneimage">
												<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp01.jpg') + ')'}">
												</div>
											</div>
										</div>
										<div class="list__data">
											<div class="list__box flex">
												<div class="list__slogan info-slogan">
													환상적인 루프탑이 있을까요? 없을까요?
												</div>
												<div class="list__grad info-grad">
												<span class="list__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
													<span class="list__gradnum info-gradnum">
													9.7
												</span>
													<span class="list__gradcounter info-gradcounter">
													(2,400)
												</span>
												</div>
											</div>
											<h3 class="list__title info-title">
												전주 한옥에코빌리지 펜션
											</h3>
											<div class="list__distance info-distance">
												역삼역 1번 출구에서 145m
											</div>
											<div class="list__have info-have">
												최대 대실 5시간 객실 보유
											</div>
										</div>
										<ul class="list__info">
											<li>
												<div class="list__info-type">
													<span class="list__badge">대실</span>
													5시간
												</div>
												<div class="list__info-price">
													<div class="list__info-current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
											<li>
												<div class="list__info-type">
													<span class="list__badge list__badge--strong">숙박</span>
													18시부터
												</div>
												<div class="list__info-price">
													<div class="info-price__sale">
														<strong>14%</strong>
														<span>35,000</span>
													</div>
													<div class="list__info-current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
										</ul>
										<div class="list__hashtag">
											<span>#서울모텔</span>
											<span>#강남모텔</span>
											<span>#셀프체크인</span>
										</div>
										<div class="getevent">
											<div class="getevent__coupon">단골쿠폰</div>
											<div class="getevent__wrap">
												<div class="getevent__title">
													이벤트
												</div>
												<p class="getevent__description ellipsis">
													주중 무한대실 이벤트! 최장 10시간
													주중 무한대실 이벤트! 최장 10시간
												</p>
											</div>
										</div>
									</div>
								</router-link>
								<ul class="item__menu">
									<li>
										<a href="#">
											<i class="icon icon-pick--point">쿠폰</i>
										</a>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<div class="item">
								<a class="list__link">
									<!-- [D] 뱃지 있을 시 추가 -->
									<div class="card__badge badge badge--coupon">
										<span class="badge__text">
											1만원 할인쿠폰
										</span>
									</div>
									<div class="list__wrap">
										<div class="list__imagebox noneimage">
											<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp01.jpg') + ')'}">
											</div>
										</div>
										<div class="list__data">
											<div class="list__slogan info-slogan">
												환상적인 루프탑이 있을까요? 없을까요?
											</div>
											<h3 class="list__title info-title">
												전주 한옥에코빌리지 펜션
											</h3>
											<div class="list__distance info-distance">
												역삼역 1번 출구에서 145m
											</div>
											<div class="list__grad info-grad">
												<span class="list__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
												<span class="list__gradnum info-gradnum">
													9.7
												</span>
												<span class="list__gradcounter info-gradcounter">
													(2,400)
												</span>
											</div>
											<div class="list__have info-have">
												최대 대실 5시간 객실 보유
											</div>
										</div>
										<ul class="list__info">
											<li>
												<div class="list__info-type">
													<span class="list__badge">대실</span>
													5시간
												</div>
												<div class="list__info-price">
													<div class="list__info-current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
											<li>
												<div class="list__info-type">
													<span class="list__badge list__badge--strong">숙박</span>
													18시부터
												</div>
												<div class="list__info-price">
													<div class="list__info-current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
										</ul>
										<div class="list__hashtag">
											<span>#서울모텔</span>
											<span>#강남모텔</span>
											<span>#셀프체크인</span>
										</div>
										<div class="getevent">
											<div class="getevent__wrap">
												<div class="getevent__title">
													이벤트
												</div>
												<p class="getevent__description ellipsis">
													주중 무한대실 이벤트! 최장 10시간
													주중 무한대실 이벤트! 최장 10시간
												</p>
											</div>
										</div>
									</div>
								</a>
								<ul class="item__menu">
									<li>
										<a href="#">
											<i class="icon icon-pick">쿠폰</i>
										</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>